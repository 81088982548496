//development
export const API_URL = "https://tutorapi.appgrowthcompany.com/api/v1/";

//live link
// export const API_URL = "https://api.tutorhail.com/api/v1/";

export const MEDIA_UPLOAD = `${API_URL}Upload/uploadFile`;
export const GOOGLE_API_KEY = "AIzaSyAtmkzL_1Y9LLgOXknagjGh_-TZUbE3U_A";
export const Linkedin_clientId = "78nu6rctvt5uf7";

export const LinkedIn_clientSecret =
  "WPL_AP0.nOiZN4xazC0GjIJp.MzYwMzEwNzIzMQ==";

export const Linkedin_redirect_url =
  "http://localhost:3000/auth/as-parent/login/linkedin/callback";

export const END_POINTS = {
  //auth
  signup: "signup",
  socialLogin: "socialLogin",
  login: "login",
  mediaUpload: "upload",
  get_user: "getProfile",
  logout: "logout",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "resetPassword",
  changePassword: "changePassword",
  updateProfile: "updateProfile",
  deleteProfile: "deleteProfile",
  sendOtp: "sendOtp",
  linkedinLogin: "linkedInToken",

  //tutor profile setup
  addBank: "addBank",
  getBank: "getBank",
  updateBank: "updateBank",
  deleteBank: "deleteBank",
  teachingDetails: "teachingDetails",
  deleteTeachingDetails: "deleteTeachingDetails",
  deleteSubjectDetails: "deleteSubjectDetails",
  getTeachingDetails: "getTeachingDetails",
  addDocuments: "addDocuments",
  getDocuments: "getDocuments",
  updateDocuments: "updateDocuments",
  deleteDocuments: "deleteDocuments",
  withdraw: "withdraw",
  getBooking: "getBooking",
  updateBooking: "updateBooking",
  pairingOtp: "pairingOtp",
  contentMaterial: "contentMaterial",
  reviews: "reviews",
  notification: "notification",
  addRating: "addRating",

  // CMS
  cms: "cms",

  // DashBoard
  DashBoard: "dashboard",
  homepage: "homepage",
  subject: "subject",
  search: "dashboardSearch",

  // address
  addAddress: "addAddress",
  getAddress: "getAddress",
  updateAddress: "updateAddress",
  deleteAddress: "deleteAddress",

  // study Material
  studyMaterial: "studyMaterial",

  // booking
  timeCheck: "timeCheck",
  booking: "booking",
  cancelBooking: "cancelBooking",
  verifyPairingOtp: "verifyPairingOtp",

  // tutor details
  tutor: "tutor",
  popularTutor: "popularTutor",
  recommendedTutor: "recommendedTutor",
  homepageTutor: "homepageTutor",

  // chat
  chatList: "chatList",
  chating: "chating",

  // wishlist
  getWishlist: "getWishlist",
  addWishlist: "addWishlist",
};
